import { createSlice } from "@reduxjs/toolkit";
import { aboutUsAPI } from "../api/API";
var initialStateAboutUs = {};
var slice = createSlice({
    name: "aboutUs",
    initialState: initialStateAboutUs,
    reducers: {
        setAboutUs: function (state, action) {
            return action.payload;
        },
    },
});
export var aboutUsReducer = slice.reducer;
export var setAboutUs = slice.actions.setAboutUs;
export var getAboutUsTC = function (id) { return function (dispatch) {
    aboutUsAPI
        .getAboutUs(id)
        .then(function (res) {
        dispatch(setAboutUs(res.data));
    })
        .catch(function (error) {
        console.log("AboutUs НЕ получили");
    });
}; };
