var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { getCompanysOrExpertsPosts, getInfoCompaniesPageAPI } from "../api/API";
var initialStateCompaniesPage = {
    infoPageCompanies: [],
    filterButtonsLeftBlock: [
        { id: 1, title: "Все", url: "", check: true },
        { id: 2, title: "Компании", url: "companys/", check: false },
        { id: 3, title: "Эксперты ", url: "experts/", check: false },
    ],
    filterButtonsRightBlock: [
        { id: 1, title: "Новое", sort: "", check: true },
        { id: 2, title: "Популярное", sort: "-likes", check: false },
    ],
    relevance: {},
    leftButtonUrl: "",
    leftButtonActiveName: "Лента",
    rightButtonActiveName: "Новое",
    differentCompanyPosts: {},
    isCompanyPageDataLoading: true,
};
var companiesPageReducer = createSlice({
    name: "CompaniesData",
    initialState: initialStateCompaniesPage,
    reducers: {
        getInfoPageCompanies: function (state, action) {
            state.infoPageCompanies = action.payload;
        },
        refreshAllCompaniesPost: function (state, action) {
            state.differentCompanyPosts = action.payload;
        },
        setButtonsLeftBlock: function (state, action) {
            state.filterButtonsLeftBlock.map(function (item) {
                if (item.id === action.payload.id) {
                    state.leftButtonUrl = item.url;
                    state.leftButtonActiveName = item.title;
                    return (item.check = true);
                }
                else {
                    return (item.check = false);
                }
            });
        },
        setButtonsSortToDefault: function (state) {
            state.filterButtonsLeftBlock.map(function (item) {
                if (item.id === 1) {
                    return (item.check = true);
                }
                else {
                    return (item.check = false);
                }
            });
            state.filterButtonsRightBlock.map(function (item) {
                if (item.id === 1) {
                    return (item.check = true);
                }
                else {
                    return (item.check = false);
                }
            });
            state.leftButtonUrl = "";
            state.leftButtonActiveName = "Лента";
            state.rightButtonActiveName = "Новое";
            state.relevance = {};
        },
        setButtonsRightBlock: function (state, action) {
            state.filterButtonsRightBlock.map(function (item) {
                if (item.id === action.payload.id) {
                    state.rightButtonActiveName = action.payload.title;
                    if (action.payload.sort === "-likes") {
                        state.relevance = __assign(__assign({}, state.relevance), { order: action.payload.sort });
                    }
                    else {
                        state.relevance = {};
                    }
                    return (item.check = true);
                }
                else {
                    return (item.check = false);
                }
            });
        },
        setCompanyPageDataLoading: function (state, action) {
            state.isCompanyPageDataLoading = action.payload.isLoading;
        },
    },
});
export default companiesPageReducer.reducer;
export var getInfoPageCompanies = (_a = companiesPageReducer.actions, _a.getInfoPageCompanies), setButtonsSortToDefault = _a.setButtonsSortToDefault, refreshAllCompaniesPost = _a.refreshAllCompaniesPost, setButtonsLeftBlock = _a.setButtonsLeftBlock, setButtonsRightBlock = _a.setButtonsRightBlock, setCompanyPageDataLoading = _a.setCompanyPageDataLoading;
export var getInfoCompaniesPageData = function () {
    return function (dispatch) {
        dispatch(setCompanyPageDataLoading({ isLoading: true }));
        getInfoCompaniesPageAPI
            .getInfoCompaniesPageData()
            .then(function (response) {
            dispatch(getInfoPageCompanies(response.data));
            dispatch(setCompanyPageDataLoading({ isLoading: false }));
        })
            .catch(function (error) { });
    };
};
export var getCompanysOrExpertsPost = function (url, data) {
    return function (dispatch) {
        getCompanysOrExpertsPosts.getDifferentPostsForCompanies(url, data).then(function (response) {
            dispatch(refreshAllCompaniesPost(response.data));
        });
    };
};
export var fetchCompanysOrExpertsPostWithPagination = function (pageParam, url, relevance) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getCompanysOrExpertsPosts.getDifferentPostsForCompaniesWithPagination(pageParam, url, relevance)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_1 = _a.sent();
                console.log(err_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getAllPostForAll = function () {
    return function (dispatch) {
        getCompanysOrExpertsPosts.getAllPostsFromAll().then(function (response) {
            dispatch(refreshAllCompaniesPost(response.data));
        });
    };
};
export var getAllPostForAllWithPagination = function (pageNumber) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, getCompanysOrExpertsPosts.getAllPostsFromAllWithPagination(pageNumber)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_2 = _a.sent();
                console.log(err_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
