var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { createNewsAPI, newsAPI, openNewsAPI } from "../api/API";
import { changeLoading } from "./loaderReduser";
import { setActive, setMessageToUserForModal, setModal } from "./modalReducer";
import { setRightSet } from "./rightSetReducer";
var initialStateNews = {
    allTags: [],
    categoriesForNews: {},
    typeMaterial: 4,
    refreshMaterialId: null,
    newsAndEventsDataForRefresh: {},
    createEvent: false,
    publicationNews: {},
    isLoading: false,
    isNewsPageDataLoading: true,
    publicationFeed: [],
    eventThemes: {},
    currentTags: [],
    locationsForPublication: {},
    formForEvents: {},
    pageForCompany: [
        { name: "Публикации", page: 4 },
        { name: "События", page: 2 },
    ],
    pageForExpert: [{ name: "Публикации", page: 4 }],
    pageForAdmin: [
        { name: "Публикации", page: 4 },
        { name: "События", page: 2 },
        { name: "Статьи", page: 3 },
        { name: "Новости в главной", page: 1 },
    ],
};
var slice = createSlice({
    name: "news",
    initialState: initialStateNews,
    reducers: {
        setNews: function (state, action) {
            return action.payload;
        },
        setPublicationsNews: function (state, action) {
            return __assign(__assign({}, state.publicationNews), { results: __spreadArray(__spreadArray([], state.publicationNews.results, true), action.payload.results, true) });
        },
        createEventToogle: function (state, action) {
            state.createEvent = action.payload;
        },
        getAllTags: function (state, action) {
            state.allTags = action.payload;
        },
        addCurrentTag: function (state, action) {
            state.currentTags.push({
                name: action.payload,
                id: Math.round(Math.random() * 1000),
            });
        },
        deleteCurrentTag: function (state, action) {
            state.currentTags = state.currentTags.filter(function (tag) {
                return tag.id !== action.payload;
            });
        },
        setCurrentTags: function (state, action) {
            state.currentTags = action.payload;
        },
        removeAllTags: function (state) {
            state.currentTags = [];
        },
        getCatigories: function (state, action) {
            state.categoriesForNews = action.payload;
        },
        setEventThemes: function (state, action) {
            state.eventThemes = action.payload;
        },
        setLocationsForPublications: function (state, action) {
            state.locationsForPublication = action.payload;
        },
        setEventFormForEvents: function (state, action) {
            state.formForEvents = action.payload;
        },
        setTypeMaterial: function (state, action) {
            state.typeMaterial = action.payload;
        },
        setRefreshMaterialId: function (state, action) {
            state.refreshMaterialId = action.payload;
        },
        setNewsAndEvantsDataForRefresh: function (state, action) {
            state.newsAndEventsDataForRefresh = action.payload;
        },
        getClearPublicationNewsFeed: function (state) {
            state.publicationNews = {};
        },
        setNewsPageDataLoading: function (state, action) {
            state.isNewsPageDataLoading = action.payload.isLoading;
        },
    },
});
export var newsReducer = slice.reducer;
export var setNews = (_a = slice.actions, _a.setNews), getAllTags = _a.getAllTags, setEventThemes = _a.setEventThemes, getCatigories = _a.getCatigories, setTypeMaterial = _a.setTypeMaterial, createEventToogle = _a.createEventToogle, setRefreshMaterialId = _a.setRefreshMaterialId, setNewsAndEvantsDataForRefresh = _a.setNewsAndEvantsDataForRefresh, setPublicationsNews = _a.setPublicationsNews, getClearPublicationNewsFeed = _a.getClearPublicationNewsFeed, setLocationsForPublications = _a.setLocationsForPublications, setEventFormForEvents = _a.setEventFormForEvents, setNewsPageDataLoading = _a.setNewsPageDataLoading, addCurrentTag = _a.addCurrentTag, deleteCurrentTag = _a.deleteCurrentTag, removeAllTags = _a.removeAllTags, setCurrentTags = _a.setCurrentTags;
export var getNewsTC = function () { return function (dispatch) {
    dispatch(setNewsPageDataLoading({ isLoading: true }));
    newsAPI
        .getNews()
        .then(function (res) {
        var _a;
        dispatch(setNews(res.data));
        dispatch(setRightSet((_a = res.data) === null || _a === void 0 ? void 0 : _a.right));
        dispatch(setNewsPageDataLoading({ isLoading: false }));
    })
        .catch(function (error) {
        dispatch(setNewsPageDataLoading({ isLoading: false }));
        console.log("Новости не получили" + error);
    });
}; };
export var getPublicationNews = function (numberPage) { return function (dispatch) {
    // dispatch(changeNewsLoading({ isLoading: true }));
    newsAPI
        .getPublicationNews(numberPage)
        .then(function (res) {
        dispatch(setPublicationsNews(res.data));
        // dispatch(changeNewsLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log("Новости не получили" + error);
    });
}; };
// перенести логику написанную ниже в отдельный редьюсер ???
export var createNewsTC = function (data) { return function (dispatch) {
    createNewsAPI
        .addNews(data)
        .then(function (res) {
        if (res.data.detail) {
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
        else {
            dispatch(setMessageToUserForModal("Кажется, вы не заполнили обязательные поля. Попробуйте ещё раз."));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
    })
        .catch(function (res) {
        console.log("Ошибка создания статьи", res);
        dispatch(setMessageToUserForModal(res.data.detail));
        dispatch(setModal("error-alert-modal"));
        dispatch(setActive(true));
    });
}; };
export var getLocationForPublication = function () { return function (dispatch) {
    createNewsAPI
        .getLocationsForPublicationa()
        .then(function (res) {
        dispatch(setLocationsForPublications(res.data));
    })
        .catch(function (res) {
        console.log("Ошибка получения для данных формы создлани я", res);
    });
}; };
export var getEventFormForEvents = function () { return function (dispatch) {
    createNewsAPI
        .getFormForEvents()
        .then(function (res) {
        dispatch(setEventFormForEvents(res.data));
    })
        .catch(function (res) {
        console.log("Ошибка получения для данных формы создлани я", res);
    });
}; };
export var getNewsAndEventsDataForRefresh = function (id) { return function (dispatch) {
    openNewsAPI
        .getNewsAndEventsDataById(id)
        .then(function (res) {
        dispatch(setNewsAndEvantsDataForRefresh(res.data));
    })
        .catch(function (error) {
        console.log(error);
    });
}; };
export var stopPublicationMaterial = function (id, typeActiveButton) { return function (dispatch) {
    if (typeActiveButton === 4) {
        dispatch(changeLoading({ isLoading: true }));
        newsAPI
            .stopPublicationVacancy(id)
            .then(function (res) {
            dispatch(changeLoading({ isLoading: false }));
        })
            .catch(function (error) {
            dispatch(changeLoading({ isLoading: false }));
            console.log(error);
        });
    }
    else {
        dispatch(changeLoading({ isLoading: true }));
        newsAPI
            .stopPublicationFunc(id)
            .then(function (res) {
            dispatch(changeLoading({ isLoading: false }));
        })
            .catch(function (error) {
            dispatch(changeLoading({ isLoading: false }));
            console.log(error);
        });
    }
}; };
export var getTagsForNews = function () { return function (dispatch) {
    createNewsAPI.getTagsForCreateNews().then(function (res) {
        dispatch(getAllTags(res.data));
    });
}; };
export var getEventThemes = function () { return function (dispatch) {
    createNewsAPI.getEventTheme().then(function (res) {
        dispatch(setEventThemes(res.data));
    });
}; };
export var getCategoriesForNews = function () { return function (dispatch) {
    createNewsAPI.getCategories().then(function (res) {
        dispatch(getCatigories(res.data));
    });
}; };
export var fetchPublicationOnMainPageWithPagination = function (currentPage, body) {
    if (currentPage === void 0) { currentPage = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, newsAPI.getPublicationNewsWithPagination(currentPage, body)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
