var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { menuAPI } from "../api/API";
var initialStateMenu = {
    isMenuAndHeaderDataLoding: false,
};
var slice = createSlice({
    name: "menu",
    initialState: initialStateMenu,
    reducers: {
        setMenu: function (state, action) {
            return action.payload;
        },
        decrementCountVacancyInMenu: function (state) {
            state.results[0].menu = state.results[0].menu.map(function (menu_item) {
                if (menu_item.name === "Вакансии") {
                    return __assign(__assign({}, menu_item), { unit_count: --menu_item.unit_count });
                }
                else {
                    return menu_item;
                }
            });
        },
        incrementCountVacancyInMenu: function (state) {
            state.results[0].menu = state.results[0].menu.map(function (menu_item) {
                if (menu_item.name === "Вакансии") {
                    return __assign(__assign({}, menu_item), { unit_count: ++menu_item.unit_count });
                }
                else {
                    return menu_item;
                }
            });
        },
        setMenuAndHeaderDataLoading: function (state, action) {
            state.isMenuAndHeaderDataLoding = action.payload.isLoading;
        },
    },
});
export var menuReducer = slice.reducer;
export var setMenu = (_a = slice.actions, _a.setMenu), decrementCountVacancyInMenu = _a.decrementCountVacancyInMenu, incrementCountVacancyInMenu = _a.incrementCountVacancyInMenu, setMenuAndHeaderDataLoading = _a.setMenuAndHeaderDataLoading;
export var getMenuTC = function () { return function (dispatch) {
    menuAPI
        .getMenu()
        .then(function (res) {
        dispatch(setMenu(res.data));
        dispatch(setMenuAndHeaderDataLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log("Меню не получили");
    });
}; };
