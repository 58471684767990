var _a, _b;
import { createSlice } from "@reduxjs/toolkit";
import { setBookmarkPost } from "./reducers/companyByIdReducer/bookmark/bookmark";
var initialState = {
    isSuccessAlertOpen: false,
    isCoockiesAgreementOpen: false,
    isErrorAlertOpen: false,
    isAuthModalOpen: false,
    isRegModalOpen: false,
    modal_name: "",
    isActive: false,
    ErrorToUserForModal: "",
    MessageToUserForModal: {
        detail: "",
    },
    registrationBtns: [
        { id: 1, role: 3, title: "Пользователь", checked: true },
        { id: 2, role: 1, title: "Компания", checked: false },
        { id: 3, role: 2, title: "Эксперт", checked: false },
        { id: 4, role: 4, title: "Стартап", checked: false },
    ],
    contactContent: '',
};
export var modalSlice = createSlice({
    name: "modal",
    initialState: initialState,
    reducers: {
        setModal: function (state, action) {
            state.modal_name = action.payload;
        },
        setActive: function (state, action) {
            state.isActive = action.payload;
        },
        setErrorMessageToUserForModal: function (state, action) {
            state.ErrorToUserForModal = action.payload;
        },
        setMessageToUserForModal: function (state, action) {
            state.MessageToUserForModal = action.payload;
        },
        setCheckedRegistrationBtns: function (state, action) {
            state.registrationBtns.map(function (button) {
                if (button.id === action.payload.id) {
                    return (button.checked = true);
                }
                else {
                    return (button.checked = false);
                }
            });
        },
        setRegButtonsToDefault: function (state) {
            state.registrationBtns.map(function (btn) { return (btn.id === 1 ? (btn.checked = true) : (btn.checked = false)); });
        },
        setSuccessAlertOpenStatus: function (state, action) {
            state.isSuccessAlertOpen = action.payload.isOpen;
        },
        setIsCoockesAgrementOpenStatus: function (state, action) {
            state.isCoockiesAgreementOpen = action.payload.isOpen;
        },
        setErrorAlertOpenStatus: function (state, action) {
            state.isErrorAlertOpen = action.payload.isOpen;
        },
        setAuthModalOpenStatus: function (state, action) {
            state.isAuthModalOpen = action.payload.isOpen;
        },
        setRegModalOpenStatus: function (state, action) {
            state.isRegModalOpen = action.payload.isOpen;
        },
        setContactContent: function (state, action) {
            state.contactContent = action.payload;
        },
    },
    extraReducers: (_a = {},
        _a[setBookmarkPost.fulfilled.type] = function (state, action) {
            state.MessageToUserForModal = action.payload;
        },
        _a),
});
export default modalSlice.reducer;
export var setModal = (_b = modalSlice.actions, _b.setModal), setActive = _b.setActive, setErrorMessageToUserForModal = _b.setErrorMessageToUserForModal, setMessageToUserForModal = _b.setMessageToUserForModal, setCheckedRegistrationBtns = _b.setCheckedRegistrationBtns, setRegButtonsToDefault = _b.setRegButtonsToDefault, setSuccessAlertOpenStatus = _b.setSuccessAlertOpenStatus, setErrorAlertOpenStatus = _b.setErrorAlertOpenStatus, setAuthModalOpenStatus = _b.setAuthModalOpenStatus, setRegModalOpenStatus = _b.setRegModalOpenStatus, setIsCoockesAgrementOpenStatus = _b.setIsCoockesAgrementOpenStatus, setContactContent = _b.setContactContent;
