var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isPopupOpen: false,
    popupTitle: "",
    messageToUserForPopup: "",
};
export var slice = createSlice({
    name: "popup",
    initialState: initialState,
    reducers: {
        getClosePopup: function (state) {
            state.isPopupOpen = false;
        },
        getOpenPopup: function (state) {
            state.isPopupOpen = true;
        },
        setMessageToUserForPopup: function (state, action) {
            state.messageToUserForPopup = action.payload;
        },
        setTitleForPopup: function (state, action) {
            state.popupTitle = action.payload;
        },
    },
});
export default slice.reducer;
export var setTitleForPopup = (_a = slice.actions, _a.setTitleForPopup), setMessageToUserForPopup = _a.setMessageToUserForPopup, getOpenPopup = _a.getOpenPopup, getClosePopup = _a.getClosePopup;
